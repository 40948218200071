document.addEventListener('DOMContentLoaded', () => {
  var sensors = window['sensorsDataAnalytic201505'];
  var server_url = "https://miracleplus.datasink.sensorsdata.cn/sa?project=default&token=41835047702812e8";
  sensors.init({
    server_url: server_url,
    heatmap: {
      //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
      clickmap: 'default',
      collect_tags: {
        // 默认不采集以下标签的点击事件，如果需要采集，设置为 true
        li: true
      },
      //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
      scroll_notice_map: 'default'
    },
    use_client_time: true,
    send_type: 'beacon',
    source_channel: ['p', 'c', 'r']
  });
  sensors.quick('autoTrack');
  sensors.use('PageLeave');
  sensors.use('PageLoad');
})
